import React, { FunctionComponent, useContext, useState, useEffect } from "react";
import { DashboardContext, DashboardContextProps } from "/src/contexts/DashboardContext"
import { User } from "/src/domain/shared/User";
import { Club } from "/src/domain/club/ClubData";
import { StudentInfo, FeedData, TrainerBaseInfo } from "/src/domain/dashboard/DashboardData";
import InfiniteScroll from 'react-infinite-scroll-component';
import { TaskView } from "/src/ui/studentTasks/studentTasks/TaskView";
import { PostView } from "/src/ui/studentTasks/studentTasks/posts/PostView";
import { Box } from "@mui/material";
import { DialogProgress } from '/src/ui/studentTasks/studentTasks/actions/DialogProgress';
import { useTranslation } from "react-i18next";
import { TestMode, TestUids } from "/src/domain/shared/checkUserForTestee";

interface FeedViewProps {
    studentInfo?: StudentInfo,
    trainerInfo?: TrainerBaseInfo,
    feed: FeedData;
    offset: number;
    hasMore: boolean;
    trainerId?: string;
    currentUser?: User;
    className?: string;
    isReadOnly: boolean;
    userClub: Club | null;
}

export const FeedView: FunctionComponent<FeedViewProps> = ({
    studentInfo, 
    trainerInfo,
    feed,
    offset,
    hasMore,
    trainerId,
    currentUser,
    className,
    isReadOnly,
    userClub,
}) => {
    const { t } = useTranslation();
    const [isDisplayAllFeed, setDisplayAllFeed] = useState(true);

    // const postEndObserver = React.useRef<IntersectionObserver | null>(null);

    const { 
        sendTaskReport, 
        currentDate,
        getTags,
        // sendViewedPost,
        expandFeed,
    } = useContext(DashboardContext) as DashboardContextProps;


    // useEffect(() => {
    //     if (!isFeed) return;

    //     const observer = new IntersectionObserver(
    //         entries => {
    //             entries.forEach(entry => {
    //                 const id = (entry.target as HTMLElement).dataset.id;
    
    //                 if (id === undefined) {
    //                     return;
    //                 }

    //                 if (entry.isIntersecting) {
    //                     const isNotSent = !sentViewedPosts.includes(id);
    //                     isNotSent && sendViewedPost(id);
    //                     isNotSent && sentViewedPosts.push(id);
    //                 }
    //             });
    //         }
    //     );
    //     postEndObserver.current = observer;
    // }, [])

    return <Box className={className}>
      <InfiniteScroll
          dataLength={feed.length}
          next={() => expandFeed(userClub)}
          hasMore={hasMore}
          loader={<DialogProgress />}
      >
        {feed.map((obj) => {
            return <React.Fragment 
                key={"postid" in obj ? obj.postid 
                    : obj.id + String(obj.studentInfo?.id) + String(obj.tracks?.[0].id)}
            >
                { TestUids.check(TestMode.STRO, currentUser?.id) && obj.category && <Box 
                    sx={{ 
                        textAlign: "center",
                        fontSize: "1.25rem"
                    }}
                >
                    { obj.category }
                </Box> }
                {"postid" in obj ? <>{
                    TestUids.customCheck([TestUids.nataSBDClubAdmin, TestUids.clubSBDManager], obj.userid) ? 
                    null
                    : <>
                        <PostView
                            {...obj}
                            type="post"
                            currentDate={currentDate}
                            isReadOnly={isReadOnly}
                            isPreview
                            currentUserId={currentUser?.id}
                            isRepostAvailable={Boolean(trainerInfo && trainerInfo?.id === currentUser?.id)}
                            isRepostedPost={false}
                            userClub={userClub}
                            isShortHeader={false}
                        />
                        {/* <PostEndDiv
                            postId={obj.postid}
                            observer={postEndObserver.current}
                        /> */}
                    </>
                }</>
                : <TaskView
                    task={obj}
                    studentInfo={studentInfo}
                    readOnly={isReadOnly}
                    isTribunalUser={false}
                    currentDate={currentDate}
                    trainerId={trainerId}
                    currentUser={currentUser}
                    sendTaskReport={sendTaskReport}
                    getTags={getTags}
                />}
            </React.Fragment>
        })}
      </InfiniteScroll>
    </Box>
}

interface PostEndDivProps {
    observer: IntersectionObserver | null;
    postId: string;
}

const PostEndDiv: FunctionComponent<PostEndDivProps> = ({
    observer,
    postId,
}) => {
    const [ref, setRef] = React.useState<HTMLElement | null>(null);

    React.useEffect(() => {
        if (ref) {
            observer?.observe(ref);
        }
        return () => {
            if (ref) {
                observer?.unobserve(ref);
            }
        };
    }, [observer, ref]);

    return <Box data-id={postId} ref={setRef} />
}

const sentViewedPosts: string[] = [];
