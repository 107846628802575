import React, { FunctionComponent, useState, useContext } from 'react';
import { SubscriptionItem } from '/src/domain/subscriptionData/SubscriptionData';
import { StudentInfo } from '/src/domain/studentTasks/StudentTasksData';
import {
  SubscriptionsContext,
  SubscriptionsContextProps,
} from '/src/contexts/SubscriptionsContext';
import { Avatar, AvatarSize } from '/src/ui/shared/Avatar';
import {
  Box,
  IconButton,
  styled,
  Snackbar,
  Alert,
  Link,
  Slide,
  SlideProps,
  MenuItem,
  Menu,
} from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { trimName } from '/src/domain/shared/trimName';
import dayjs from 'dayjs';
import { links } from '/src/domain/shared/links';
import { useTranslation } from 'react-i18next';
import { find as _find } from 'lodash';

interface PostHeaderProps {
  currentDate: string;
  date: string;
  studentInfo: StudentInfo | undefined;
  currentUserId: string | undefined;
  headerColor?: string;
  isShortHeader: boolean;
  isEditable: boolean;
  handleClickMenuItem: (value: MenuAction) => Promise<void>;
  audiosec: number | undefined;
  isReadOnly: boolean;
}

export enum MenuAction {
  DELETE,
  DELETE_AUDIO,
  EDIT,
}

export const PostHeader: FunctionComponent<PostHeaderProps> = ({
  currentDate,
  date,
  studentInfo,
  currentUserId,
  headerColor,
  isShortHeader,
  isEditable,
  handleClickMenuItem,
  audiosec,
  isReadOnly,
}) => {
  const postDateFormat =
    dayjs(currentDate).get('year') !== dayjs(date).get('year')
      ? dayjs(date).format('dddd, DD.MM.YYYY')
      : dayjs(date).format('dddd, DD.MM');

  return (
    <Box
      sx={{
        fontSize: '1.2rem',
        fontWeight: 'bold',
      }}
    >
      {studentInfo && !isShortHeader ? (
        <ExternalPostHeader
          studentInfo={studentInfo}
          postDateFormat={postDateFormat}
          currentUserId={currentUserId}
          isEditable={isEditable}
          audiosec={audiosec}
          handleClickMenuItem={handleClickMenuItem}
          isReadOnly={isReadOnly}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              fontSize: '1.2rem',
              fontWeight: 'bold',
            }}
          >
            {postDateFormat}
          </Box>
          {isEditable && (
            <EditButton
              audiosec={audiosec}
              handleClickMenuItem={handleClickMenuItem}
              isReadOnly={isReadOnly}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

interface ExternalPostHeaderProps {
  studentInfo: StudentInfo;
  postDateFormat: string;
  currentUserId: string | undefined;
  isEditable: boolean;
  audiosec: number | undefined;
  handleClickMenuItem: (value: MenuAction) => Promise<void>;
  isReadOnly: boolean;
}

const ALERT_LIFETIME = 5000;

const ExternalPostHeader: FunctionComponent<ExternalPostHeaderProps> = ({
  studentInfo,
  postDateFormat,
  currentUserId,
  isEditable,
  audiosec,
  handleClickMenuItem,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const {
    handleSubscribeToggle,
    listOfSubscriptions,
    setListOfSubscriptionsState,
  } = useContext(SubscriptionsContext) as SubscriptionsContextProps;

  const handleSubscribeClick = async () => {
    if (!currentUserId) return;

    setLoading(true);

    await handleSubscribeToggle(studentInfo.id);
    await setListOfSubscriptionsState(currentUserId);
    setOpenSnackbar(true);
    setLoading(false);
  };

  const profileLink = `${links.origin}/profile?${studentInfo?.id}`;
  const studentName = trimName(studentInfo.firstName, studentInfo.lastName);

  const showSubscribeButton =
    currentUserId &&
    currentUserId != studentInfo.id &&
    !checkSubscration(listOfSubscriptions, studentInfo.id);

  return (
    <>
      <Box
        sx={{
          color: 'secondary.main',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            fontSize: '1.3rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <HiddenLink href={profileLink}>{studentName}</HiddenLink>
          <Box
            sx={{
              fontSize: '1rem',
              fontWeight: 'normal',
            }}
          >
            {postDateFormat}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {showSubscribeButton && (
            <IconButton
              sx={{
                width: '2.5rem',
                height: '2.5rem',
                mr: '0.5rem',
              }}
              color={'success'}
              disabled={loading}
              onClick={handleSubscribeClick}
            >
              <AddIcon />
            </IconButton>
          )}
          <HiddenLink href={profileLink}>
            <Avatar
              avatarUrl={studentInfo.avatarUrl || ''}
              size={AvatarSize.MEDIUM}
              premium={studentInfo.isPremium}
            />
          </HiddenLink>
        </Box>
        <Snackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(!openSnackbar)}
          anchorOrigin={{
            horizontal: 'center',
            vertical: 'top',
          }}
          autoHideDuration={ALERT_LIFETIME}
          TransitionComponent={TransitionDown}
        >
          <Alert severity="success">
            {t('youSubscribedTo')}
            <Link href={profileLink}>{studentName}</Link>
          </Alert>
        </Snackbar>
      </Box>
      {isEditable && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <EditButton
            audiosec={audiosec}
            handleClickMenuItem={handleClickMenuItem}
            isReadOnly={isReadOnly}
          />
        </Box>
      )}
    </>
  );
};

const checkSubscration = (
  listOfSubscriptions: SubscriptionItem[] | null,
  id: string,
) => {
  return Boolean(_find(listOfSubscriptions, (i) => i.id == id));
};

type TransitionProps = Omit<SlideProps, 'direction'>;

const TransitionDown: FunctionComponent<TransitionProps> = (props) => {
  return <Slide {...props} direction="down" />;
};

const HiddenLink = styled('a')({
  color: 'inherit',
  textDecoration: 'none',
});

interface EditButtonProps {
  handleClickMenuItem: (value: MenuAction) => Promise<void>;
  audiosec: number | undefined;
  isReadOnly: boolean;
}

const EditButton: FunctionComponent<EditButtonProps> = ({
  handleClickMenuItem,
  audiosec,
  isReadOnly,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (value: MenuAction) => {
    handleClickMenuItem(value);
    handleCloseMenu();
  };
  return (
    <>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={openMenu ? 'long-menu' : undefined}
        aria-expanded={openMenu ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => handleClick(MenuAction.EDIT)}>
          <EditIcon />
          {t('edit')}
        </MenuItem>
        {Boolean(audiosec) && (
          <MenuItem onClick={() => handleClick(MenuAction.DELETE_AUDIO)}>
            <RemoveCircleIcon />
            {t('deleteAudio')}
          </MenuItem>
        )}
        {!isReadOnly && (
          <MenuItem onClick={() => handleClick(MenuAction.DELETE)}>
            <DeleteIcon />
            {t('delete')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
