import React, { FunctionComponent, useState } from 'react';
import { TrainingTask } from '/src/domain/studentTasks/StudentTasksData';
import makeStyles from '@mui/styles/makeStyles';
import { theme } from '/src/assets/theme';
import { KmForecastInput } from '/src/ui/studentTasks/studentTasks/KmForecastInput';
import { useTranslation } from 'react-i18next';
import { isNumber as _isNumber } from 'lodash';
import dayjs from "dayjs";

interface KmForecastProps {
  forecast?: string;
  task: TrainingTask;
  onChange?: (value: string) => void;
  currentDate: string;
  weekTarget?: number;
}

export const KmForecast: FunctionComponent<KmForecastProps> = ({
  forecast,
  task,
  onChange,
  currentDate,
  weekTarget,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (task.tracks?.length || dayjs(currentDate).isAfter(task.date, 'day')) return null;

  return (
    <div className={classes.forecastContainer}>
      <div className={classes.forecastData}>
        {t('forecast')}:{' '}
        {onChange ? (
          <KmForecastInput
            value={forecast}
            onChange={onChange}
            sx={{
              m: '0 0.5rem',
              width: '3rem',
            }}
            InputProps={{
              sx: {
                '& input': {
                  p: '0.25rem 0.5rem',
                },
              },
            }}
          />
        ) : (
          forecast
        )}{' '}
        {t('km')}
      </div>
        { _isNumber(weekTarget) && <>
          {t('perWeek')}: {weekTarget} {t('km')}
        </> }
    </div>
  );
};

const useStyles = makeStyles({
  forecastContainer: {
    minWidth: '10rem',
    padding: '0.25rem 0.5rem',
    backgroundColor: theme.primarySurfaceColor,
    borderRadius: '5px',
    width: '95%',
    boxSizing: 'border-box',
  },
  forecastData: {
    display: 'flex',
    alignItems: 'center',
  },
  forecastHint: {
    fontSize: '0.8rem',
    marginTop: '0.25rem',
    color: theme.greyTextColor,
  },
});
