import React, { FunctionComponent } from 'react';
import { TrainingType } from '/src/domain/studentTasks/StudentTasksData';
import { trainingText } from '/src/domain/studentTasks/trainingInfo';
import { FormControl, Select, MenuItem, SxProps, 
  FormControlLabel, Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SelectTaskTypeProps {
  type: TrainingType;
  onTypeChange: (value: TrainingType) => void;
  main6?: boolean;
  onMain6Change: (value: boolean) => void;
  sx?: SxProps;
  isFutureTask: boolean;
}

export const SelectTaskType: FunctionComponent<SelectTaskTypeProps> = ({
  type,
  onTypeChange,
  main6,
  onMain6Change,
  sx,
  isFutureTask,
}) => {
  const { t } = useTranslation();

  return (
    <FormControl
      variant="outlined"
      fullWidth
      sx={sx}
    >
      <Select
        name="training-type"
        size="small"
        required
        value={type}
        onChange={(e) => {
          onTypeChange(Number(e.target.value));
        }}
      >
        {Object.keys(TrainingType)
          .filter((v) => isNaN(Number(v)))
          .map((key) => {
            const typeEnumValue =
              TrainingType[key as keyof typeof TrainingType];
            return (
              <MenuItem value={typeEnumValue} key={typeEnumValue}>
                {t(trainingText[typeEnumValue]) || t('typeOfTraining')}
              </MenuItem>
            );
          })}
      </Select>
      {type == TrainingType.COMPETITION && isFutureTask && <FormControlLabel 
        control={<Switch 
          checked={!!main6}
          onChange={(e) => onMain6Change(e.target.checked)}
        />} 
        
        label={t('selectAsMain')} 
      />}
    </FormControl>
  );
};
