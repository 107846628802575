import React, { FunctionComponent, useCallback, useEffect, useContext, useState } from "react";
import { PostContext, PostContextProps } from "/src/contexts/PostContext";
import { ClubProvider } from "/src/contexts/ClubContext";
import { PostViewMode, StudentInfo } from "/src/domain/studentTasks/StudentTasksData";
import { Club } from "/src/domain/club/ClubData";
import { DialogListWrapper } from "/src/ui/studentTasks/studentTasks/actions/DialogListWrapper";
import { DialogProgress } from "/src/ui/studentTasks/studentTasks/actions/DialogProgress";
import { RichTextEditor, EditorMode } from "../sendReport/RichTextEditor";
import { DialogContent, Box, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import { Editor as TinyMCEEditor } from 'tinymce';
import { AuidoEditor } from './AuidoEditor';
import { SelectPostStatus, NewPostKeys, hasStatusEditGodRights } from './SelectPostStatus';
import { SavingPostInstructionsDialog } from './SavingPostInstructionsDialog';
import { TagsField } from "/src/ui/studentTasks/studentTasks/TagsField";
import { useTranslation } from "react-i18next";
import { TestMode, TestUids } from "/src/domain/shared/checkUserForTestee";

interface PostEditorProps {
    open: boolean;
    onClose: () => void;
    editorRef: React.MutableRefObject<TinyMCEEditor | null>;
    postId: string | null;
    savePost: () =>  Promise<boolean>;
    loading: boolean;
    initialValue?: string;
    viewMode: PostViewMode;
    setViewMode: (value: PostViewMode) => void;
    currentUserId?: string;
    audiosec?: number;
    newAudioFile: File | null;
    setNewAudioFile: (newAudioFile: File | null) => void;
    tags: string[];
    setTags: (newTags: string[]) => void;
    postKeys?: string[];
    newPostKeys: NewPostKeys;
    setNewPostKeys: (newPostKeys: NewPostKeys) => void;
    userClub: Club | null;
    postOwnerInfo: StudentInfo | undefined;
    clubManagement?: Club;
}

export const PostEditor: FunctionComponent<PostEditorProps> = ({
    open,
    onClose,
    editorRef,
    postId,
    savePost,
    loading,
    initialValue = "",
    viewMode,
    setViewMode,
    currentUserId,
    audiosec,
    newAudioFile,
    setNewAudioFile,
    tags,
    setTags,
    postKeys,
    newPostKeys,
    setNewPostKeys,
    userClub,
    postOwnerInfo,
    clubManagement,
}) => {
    const { t } = useTranslation();
    const [openInstructionsDialog, setOpenInstructionsDialog] = useState(false);

    const { getTags } = useContext(PostContext) as PostContextProps;

    const handleBeforeUnload = useCallback((event: BeforeUnloadEvent) => {
        event.preventDefault();
        event.returnValue = '';
    }, []);

    useEffect(() => {
        if (!open) {
            return window.removeEventListener('beforeunload', handleBeforeUnload);
        }
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [open])


    const closeDialogHandler = () => {
        if (confirm(t('dataLossFromDialog'))) {
            onClose();
        }
    }

    // const handleChange = (event: SelectChangeEvent) => {
    //     setViewMode(Number(event.target.value) as PostViewMode);
    // };

    const isTestAudio = 
        audiosec || 
        TestUids.customCheck([
            TestUids.stro, 
            TestUids.paidWithTrainer, 
            TestUids.dutyTrainer, 
            TestUids.antoninaVitalievna, 
            TestUids.veraDervoedova,
            TestUids.andreiCebanenco,
            TestUids.evgenyDenisovCoach,
            TestUids.evgenyDenisov,
            TestUids.nata,
            TestUids.brandedSportsNata,
            TestUids.vladimirGrezovCoach,
            TestUids.vladimirGrezov,
            TestUids.nikitaVolodarskiy,
            TestUids.dmitryPoznyshevCoach,
            TestUids.alexeySidorenkoCoach,
            TestUids.ilyaTokachirov,
            TestUids.s10Runner,
            TestUids.petrShibanov,
        ], currentUserId);
    const isTestViewMode = TestUids.check(TestMode.PAID_WITH_TRAINER_AND_STRO, currentUserId);

    const handleSaveButtonClick = () => {
        const content = editorRef.current?.getContent();

        if (!content) {
            setOpenInstructionsDialog(true);
            return;
        }
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, "text/html");
        const firstH1 = doc.querySelector("h1");

        if (!firstH1?.textContent?.trim()) {
            setOpenInstructionsDialog(true);
            return;
        }

        savePost();
    }

    return <DialogListWrapper
        open={open} 
        onClose={closeDialogHandler} 
        disableEnforceFocus
        fullWidth
        PaperProps={{
            sx: { height: "100%" }
        }}
    >
        <DialogContent sx={{
            display: "flex",
            flexDirection: "column",
        }}>
            {currentUserId && <TagsField
                studentId={currentUserId}
                tags={tags}
                setTags={setTags}
                getTags={getTags}
            />}
            <Box sx={{
                minHeight: "30rem",
                flexGrow: 1
            }}>
                {postId ? <RichTextEditor
                    type={EditorMode.POST}
                    imageSaveId={postId}
                    editorRef={editorRef}
                    initialValue={initialValue}
                />
                : <DialogProgress />}
            </Box>
            {isTestAudio && <AuidoEditor 
                audiosec={audiosec}
                newAudioFile={newAudioFile}
                setNewAudioFile={setNewAudioFile}
            />}
            {postOwnerInfo && currentUserId && (
                hasStatusEditGodRights(currentUserId) || postOwnerInfo.id === currentUserId
                ) && <ClubProvider>
                <SelectPostStatus
                    postKeys={postKeys || []}
                    newPostKeys={newPostKeys}
                    setNewPostKeys={setNewPostKeys}
                    userClub={userClub}
                    postOwnerInfo={postOwnerInfo}
                    clubManagement={clubManagement}
                />
            </ClubProvider>}
            {/* TODO postsStatus */}
            {/* {isTestViewMode && <Select
                value={String(viewMode)}
                onChange={handleChange}
                size="small"
                sx={{ mt: "0.75rem" }}
            >
                <MenuItem value={PostViewMode.REGULAR}>{ t('regular') }</MenuItem>
                <MenuItem value={PostViewMode.DRAFT}>{ t('draft') }</MenuItem>
                <MenuItem value={PostViewMode.CLUB}>{ t('club') }</MenuItem>
                <MenuItem value={PostViewMode.FEATURES}>{ t('features') }</MenuItem>
                <MenuItem value={PostViewMode.ADVERTISING}>{ t('advertising') }</MenuItem>
                <MenuItem value={PostViewMode.ANNOUNCEMENTS}>{ t('announcements') }</MenuItem>
                <MenuItem value={PostViewMode.APPEAL}>{ t('appeal') }</MenuItem>
                <MenuItem value={PostViewMode.SUGGEST_CHANGES}>{ t('suggestChanges') }</MenuItem>
            </Select>} */}
            <LoadingButton 
                variant="contained"
                sx={{
                    textTransform: "capitalize",
                    mt: "1.5rem"
                }}
                onClick={handleSaveButtonClick}
                loading={loading}
            >
                { t('saveLabel') }
            </LoadingButton>
        </DialogContent>
        <SavingPostInstructionsDialog open={openInstructionsDialog} onClose={() => setOpenInstructionsDialog(false)}/>
    </DialogListWrapper>
}
