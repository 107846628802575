import React, { FunctionComponent, useEffect, useState, useContext } from "react";
import { ProfilePrivacyContext, ProfilePrivacyContextProps } from "/src/contexts/shared/ProfilePrivacyContext";
import { ActivityNotificationType } from "/src/domain/notifications/NotificationsData";
import { Like, PreviewCommentsInfo } from "/src/domain/studentTasks/StudentTasksData";
import { Box, IconButton, ButtonBase, SxProps } from "@mui/material";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteSharpIcon from '@mui/icons-material/FavoriteSharp';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { LikesDialog } from "./LikesDialog";
import { CommentsPreview } from "./CommentsPreview";
import { CommentsDialog } from "./CommentsDialog";
import { useTranslation } from "react-i18next";
import { getLike, addLike, deleteLike } from "/src/domain/shared/requests/likeRequests";
import { getCommentsPreview } from "/src/domain/shared/requests/commentRequests";
import { RepostPostButton } from "./RepostPostButton";
import { theme } from "/src/assets/theme";

interface ActionsProps {
    entityId: string;
    currentUserId?: string;
    isReadOnly: boolean;
    type: ActivityNotificationType;
    views?: number;
    sx?: SxProps;
    isRepostAvailable?: boolean;
    isRepostedPost?: boolean;
    isRepostPreview?: boolean;
}

export const ActionsView: FunctionComponent<ActionsProps> = ({
    entityId,
    currentUserId,
    isReadOnly,
    type,
    views,
    sx,
    isRepostAvailable,
    isRepostedPost,
    isRepostPreview,
}) => {
    const { t } = useTranslation();

    const { isProfilePrivate } = useContext(ProfilePrivacyContext) as ProfilePrivacyContextProps;

    const [like, setLike] = useState<Like | null>(null);
    const [likeLoading, setLikeLoading] = useState(false);
    const [openLikesDialog, setLikesDialog] = useState(false);
    const [openCommentsDialog, setCommentsDialog] = useState(false);
    const [commentsPreview, setCommentsPreview] = useState<PreviewCommentsInfo | null>(null);
    
    const disabledLike = !like || likeLoading || isProfilePrivate;

    const setActionState = async (nocache?: Boolean) => {
        const like = await getLike(entityId, type);
        setLike(like);
        const commentsPreview = await getCommentsPreview(entityId, type, nocache);
        setCommentsPreview(commentsPreview);
    }

    useEffect(() => {
        setActionState();
    }, [])

    const handleLikeClick = async () => {
        if (!currentUserId) {
            return window.location.href = "/login";
        }

        setLikeLoading(true);

        let newLike: Like | null;
        if (like?.userLike) {
            newLike = await deleteLike(entityId, type);
        } else {
            newLike = await addLike(entityId, type);
        }

        setLike(newLike);
        setLikeLoading(false);
    }

    const handleCommentClick = () => {
        if (!currentUserId) {
            return window.location.href = "/login";
        }

        setCommentsDialog(!openCommentsDialog)
    }



    return <Box sx={sx}>
        <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <Box sx={{
                position: "relative",
                right: "0.3rem",
                display: "flex",
            }}>
                <IconButton 
                    size="small" 
                    disabled={disabledLike} 
                    onClick={handleLikeClick}
                >
                    {like?.userLike ? <FavoriteSharpIcon color="secondary"/> : <FavoriteBorderIcon/>}
                </IconButton>
                <IconButton 
                    size="small"
                    onClick={handleCommentClick}
                >
                    <ChatBubbleOutlineIcon/>
                </IconButton>
                {currentUserId && (
                    <RepostPostButton
                    currentUserId={currentUserId}
                    postId={entityId}
                    isRepostAvailable={isRepostAvailable}
                    isRepostedPost={isRepostedPost}
                    isRepostPreview={isRepostPreview}
                />
                )}
            </Box>
            {Boolean(views) && <Box sx={{ display: "flex", alignItems: "center", color: theme.onPrimaryColor }}>
                <RemoveRedEyeOutlinedIcon sx={{ m: "0 0.25rem" }}/> { views } 
            </Box>}
        </Box>
        {Boolean(like?.numberOfLikes) && <ButtonBase 
            onClick={() => setLikesDialog(!openLikesDialog)} 
            sx={{ fontSize: "inherit" }}
        >
            { t('like') } ({ like?.likeCost })
        </ButtonBase>}
        {commentsPreview && <CommentsPreview
            commentsPreview={commentsPreview}
            onClick={() => setCommentsDialog(!openCommentsDialog)}
            currentUserId={currentUserId}
        />}
        <LikesDialog
            entityId={entityId}
            open={openLikesDialog}
            onClose={() => setLikesDialog(!openLikesDialog)}
            type={type}
        />
        <CommentsDialog
            entityId={entityId}
            open={openCommentsDialog}
            onClose={() => setCommentsDialog(!openCommentsDialog)}
            currentUserId={currentUserId}
            isReadOnly={isReadOnly}
            setActionState={setActionState}
            disabled={isProfilePrivate}
            type={type}
        />
    </Box>
}
