import React, {
  FunctionComponent,
  useState,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { UserRole, User } from '/src/domain/shared/User';
import {
  CurrentUserContext,
  CurrentUserContextProps,
} from '/src/contexts/shared/CurrentUserContext';
import {
  PostData,
  PostProps,
  CurrentAudioPostData,
  PlaylistData,
  ClubFeed,
} from '/src/domain/post/PostData';
import {
  StudentInfo,
  TrainingTask,
} from '/src/domain/studentTasks/StudentTasksData';
import { Club } from '/src/domain/club/ClubData';
import { getUserInfo } from '/src/domain/shared/requests/getUserInfo';
import { getUserPrivacy } from '/src/domain/shared/requests/getUserPrivacy';
import { getTags } from '/src/domain/shared/requests/getTags';
import {
  getPostWithRecommendations,
  PostWithRecommendations,
  SavePostParams,
  savePost,
  updatePostStatus,
  deletePost,
  getPostId,
  getPosts,
  getPostsByStatus,
  GetUserPostsByLimitArgs,
  GetAllPostsArgs,
  PostsArgs,
  getNumberOfPosts,
  GetNumberOfPostsArgs,
  getAudioMark,
  saveAudioMark,
  getPlaylist,
  savePlaylist,
  getRepostedPosts,
  addRepostPost,
  deleteRepostPost,
  checkRepostedPosts,
  getAudioKey,
} from '/src/domain/shared/requests/userPostRequests';
import { useTranslation } from 'react-i18next';

import { isArray as _isArray, shuffle as _shuffle } from 'lodash';
import axios from 'axios';
import { links } from '/src/domain/shared/links';
import { TestUids } from '/src/domain/shared/checkUserForTestee';

export interface PostContextProps {
  getPostWithRecommendations: (
    postId: string,
  ) => Promise<PostWithRecommendations | null>;
  savePost: (args: SavePostParams) => Promise<PostProps | null>;
  updatePostStatus: (
    type: 'set' | 'add' | 'del',
    postId: string,
    keys: string[],
  ) => Promise<boolean>;
  deletePost: (id: string, audioOnly: boolean) => Promise<boolean>;
  getPostId: () => Promise<string | null>;
  getPosts: (obj: PostsArgs) => Promise<PostData[]>;
  isPostExists?: boolean;
  checkPostExists: (postId: string) => Promise<void>;
  postByStatus?: PostData | null;
  setPostByStatus: React.Dispatch<React.SetStateAction<PostData | null | undefined>>;
  requestPostByStatus: (params: RequestPostByStatusParams) => Promise<void>;

  similarPosts?: PostData[];
  setSimilarPostsState: (postId?: string) => Promise<void>;
  similarPostsByStatus?: PostData[] | null;

  postOwners: PostOwners;
  setPostOwnersState: (
    posts: PostData[],
    defaultUsers?: PostOwners,
  ) => Promise<PostOwners>;

  postsByStatus?: PostData[] | null;
  requestPostsByStatus: (
    params: RequestPostsByStatusParams,
  ) => Promise<PostData[] | undefined | null | number>;

  posts?: PostData[];
  setPostsState: (obj: GetUserPostsByLimitArgs) => Promise<void>;
  postsErrorCode: number | null;

  repostedPostsByStatus: InfiniteScrollPostsData;

  audioPostsByStatus: InfiniteScrollPostsData;
  getAudioKey: () => Promise<string | null>;
  countNewAudioPostsByStatus?: number | null;
  requestCountNewAudioPostsByStatus: (club: Club | null) => Promise<void>;

  addRepostPost: (postid: string) => Promise<boolean>;
  deleteRepostPost: (postid: string) => Promise<boolean>;
  checkRepostedPosts: (postId: string) => Promise<boolean>;
  repostedPostDeletedStateUpdate: (
    userId: string,
    postId: string,
    isRepostPreview?: boolean,
  ) => Promise<boolean>;

  numberOfPostsByStatus?: number | null;

  pagination: PaginationData;
  setPagination: (value: PaginationData) => void;

  postsSearchResultByStatus: InfiniteScrollPostsData;

  removePost: (id: string, audioOnly: boolean) => Promise<boolean>;
  updatePost: (args: UpdatePostParams) => Promise<boolean>;

  currentAudioPost: CurrentAudioPostData | null;
  setCurrentAudioPostState: (
    value?: CurrentAudioPostData | null,
  ) => Promise<void>;
  saveAudioMark: (
    postid: string,
    sec: number,
    isFull: boolean,
  ) => Promise<boolean>;

  totalPlaylistDuration?: number;
  playlist?: PlaylistData | null;
  setPlaylist: React.Dispatch<
    React.SetStateAction<PlaylistData | null | undefined>
  >;
  setPlaylistState: () => Promise<void>;
  updatePlaylistState: (value: string | string[]) => Promise<void>;

  getTags: (studentId?: string, tag?: string) => Promise<string[]>;

  isParticipantInCommercialChallenge: boolean;

  pinnedClubPostsByStatus?: PostData[] | null;
  requestPinnedClubpostsByStatus: (club: Club, userClub: Club | null, currentUser?: User) => Promise<void>;

  clubsFeedByStatus: ClubsFeedByStatus;
  dispatchClubsFeedByStatus: (value: ClubsFeedByStatusActionType) => void;
  requestClubsFeedByStatus: (
    club: Club,
    isClubMember: boolean,
    isGod: boolean,
  ) => Promise<void>;
}

export { SavePostParams };
export type UpdatePostParams = Omit<SavePostParams, 'create'>;

interface PaginationData {
  page: number;
  from: number;
  limit: number;
}

export const PAGINATION_STEP = 20;

interface PostOwners {
  [key: string]: StudentInfo;
}

export interface InfiniteScrollPostsData {
  posts?: PostData[] | null;
  offset: number;
  isLoading: boolean;
  hasMore: boolean;
}

export const initialInfiniteScrollPosts: InfiniteScrollPostsData = {
  offset: 0,
  isLoading: false,
  hasMore: true,
};

export type InfiniteScrollPostsActionType =
  | { type: 'FETCH_POSTS_START' }
  | { type: 'FETCH_POSTS_FINISH' }
  | {
      type: 'FETCH_POSTS_SUCCESS';
      payload: { posts: PostData[] | null; newOffset: number };
    }
  | {
      type: 'FETCH_NEW_POSTS_SUCCESS';
      payload: { posts: PostData[] | null; newOffset: number };
    }
  | { type: 'SET_HAS_MORE'; payload: boolean }
  | { type: 'REMOVE_POST_BY_ID'; payload: string };

export const reducerInfiniteScrollPosts = (
  state: InfiniteScrollPostsData,
  action: InfiniteScrollPostsActionType,
): InfiniteScrollPostsData => {
  switch (action.type) {
    case 'FETCH_POSTS_START':
      return { ...state, isLoading: true };
    case 'FETCH_POSTS_FINISH':
      return { ...state, isLoading: false };
    case 'FETCH_POSTS_SUCCESS':
      return {
        ...state,
        posts: action.payload.posts
          ? state.posts
            ? [...state.posts, ...action.payload.posts]
            : action.payload.posts
          : state.posts ?? null,
        offset: action.payload.newOffset,
      };
    case 'FETCH_NEW_POSTS_SUCCESS':
      return {
        ...initialInfiniteScrollPosts,
        posts: action.payload.posts,
        offset: action.payload.newOffset,
      };
    case 'SET_HAS_MORE':
      return { ...state, hasMore: action.payload };
    case 'REMOVE_POST_BY_ID':
      return {
        ...state,
        posts: state.posts?.filter((p) => p.postid !== action.payload),
      };
    default:
      return state;
  }
};

export const PostContext = React.createContext<PostContextProps | undefined>(
  undefined,
);

const PostIdsForLukoilClub = ['306', '146', '196', '163', '139', '131', '194', '191', '251', '305'];
const LukoilClubId = '87';

export const PostProvider: FunctionComponent = ({ children }) => {
  const { t } = useTranslation();

  const [similarPosts, setSimilarPosts] = useState<PostData[]>();
  const [similarPostsByStatus, setSimilarPostsByStatus] = useState<
    PostData[] | null
  >();

  const [postsSearchResultByStatus, dispatchPostsSearchResultByStatus] = useReducer(
    reducerInfiniteScrollPosts,
    initialInfiniteScrollPosts,
  );

  const [posts, setPosts] = useState<PostData[]>();

  const [isPostExists, setIsPostExists] = useState<boolean>();
  const [postByStatus, setPostByStatus] = useState<PostData | null>();
  const [postsByStatus, setPostsByStatus] = useState<PostData[] | null>();
  const [postsErrorCode, setPostsErrorCode] = useState<number | null>(null);

  const [pinnedClubPostsByStatus, setPinnedClubPostsByStatus] = useState<PostData[] | null>();
  const [clubsFeedByStatus, dispatchClubsFeedByStatus] = useReducer(
    reducerClubsFeedByStatus,
    initialClubsFeedByStatus,
  );

  const [isRepostsEnd, setIsRepostsEnd] = useState(false);
  const [repostedPostsByStatus, dispatchRepostedPostsByStatus] = useReducer(
    reducerInfiniteScrollPosts,
    initialInfiniteScrollPosts,
  );

  const [audioPostsByStatus, dispatchAudioPostsByStatus] = useReducer(
    reducerInfiniteScrollPosts,
    initialInfiniteScrollPosts,
  );

  const [newAudioPostsCount, setNewAudioPostsCount] = useState<number>();
  const [countNewAudioPostsByStatus, setCountNewAudioPostsByStatus] = useState<
    number | null
  >();

  const [numberOfPostsByStatus, setNumberOfPostsByStatus] = useState<
    number | null
  >();

  const [postOwners, setPostOwners] = useState<PostOwners>({});

  const [pagination, setPagination] = useState<PaginationData>({
    page: 1,
    from: 0,
    limit: PAGINATION_STEP,
  });

  const [playlist, setPlaylist] = useState<PlaylistData | null>();
  const [totalPlaylistDuration, setTotalPlaylistDuration] = useState<number>();

  const [currentAudioPost, setCurrentAudioPost] =
    useState<CurrentAudioPostData | null>(null);

  const [
    isParticipantInCommercialChallenge,
    setIsParticipantInCommercialChallenge,
  ] = useState(true);

  const { currentUser, setCurrentUserState } = useContext(
    CurrentUserContext,
  ) as CurrentUserContextProps;

  const setSimilarPostsState = async (postId?: string) => {
    const id = getIdFromUrl(postId);

    const postResponse = id ? await getPostWithRecommendations(id) : null;

    if (!postResponse?.similar) return;

    const similarsWithoutPrivate: PostData[] | undefined =
      await getPostsWithoutPrivate(postResponse.similar);

    setSimilarPosts(similarsWithoutPrivate);
  };

  const setPostOwnersState = async (
    posts: PostData[],
    defaultUsers?: PostOwners,
  ) => {
    const newPostOwners = { ...postOwners };

    for (let p of posts) {
      if (!p.userid || newPostOwners[p.userid]) {
        continue;
      }

      const userInfo = await getUserInfo(p.userid);

      if (userInfo?.id) {
        newPostOwners[userInfo.id] = {
          id: userInfo.id,
          firstName: userInfo.firstName || '',
          lastName: userInfo.firstName || '',
          isPremium: userInfo.isPremium,
          avatarUrl: userInfo.avatarUrl,
        };
      }
    }

    setPostOwners(newPostOwners);
    return newPostOwners;
  };

  const setPostsState = async (obj: GetUserPostsByLimitArgs) => {
    const posts = await getPosts(obj);
    if (!Array.isArray(posts) || !posts.length) {
      return setPostsErrorCode(404);
    } else {
      setPostsErrorCode(null);
    }

    setPosts(posts);
  };

  const checkPostExists = async (postId: string) => {
    const isPostExists = !!(await getPostsByStatus({
      keys: [[`status:post${postId}`]],
      limit: 1,
      total: true,
    }));

    setIsPostExists(isPostExists);
  }

  const requestPostByStatus = async (params: RequestPostByStatusParams) => {

    let keys: string[][] = [];
    // если Бог - любой пост
    if (
      TestUids.customCheck([TestUids.stro, TestUids.nata, TestUids.brandedSportsNata, TestUids.s10Runner], currentUser?.id)
    ) {
      keys.push([`status:post${params.postId}`]);
    } else {
      keys = [
        // публичный пост
        [`status:post${params.postId}`, `status:public`],
        // пост только для подписчиков (если является подписчиком)
        [`status:post${params.postId}`, `meta:subscriptions`],
        // закреп общий
        [`status:post${params.postId}`, `status:pinnedPublic`],
        // велком общий
        [`status:post${params.postId}`, `status:welcomePublic`],
        // велком любого клуба
        [`status:post${params.postId}`, `status:welcomeClub`],
        // избранное
        [`status:post${params.postId}`, `status:featured`],
      ];
  
      if (params.currentUserId) {
        // если автор - владелец, а пост - черновик, то вернётся черновик
        keys.push([
          `status:post${params.postId}`,
          `status:draft${params.currentUserId}`
        ]);
        // если автор - владелец, то вернётся любой пост (кроме черновика)
        keys.push([
          `status:post${params.postId}`,
          `status:author${params.currentUserId}`
        ]);
      }
  
      if (params.club) {
        // Все клубные посты, где клуб совпадает с пользователем
        keys.push([
          `status:post${params.postId}`,
          `status:club${params.club.id}`,
        ]);
      }

      if (params.managerClubId) {
        params.managerClubId.map((clubId) => {
        // Все клубные посты, где клуб совпадает с менеджером
          keys.push([
            `status:post${params.postId}`,
            `status:club${clubId}`,
          ]);
        })
      }
      if (params.trainerClubIds) {
        params.trainerClubIds.map((clubId) => {
        // Все клубные посты, где клуб совпадает с тренером
          keys.push([
            `status:post${params.postId}`,
            `status:club${clubId}`,
          ]);
        })
      }
    }

    const fetchedPostsByStatus = await getPostsByStatus({
      keys,
      limit: 1,
    });

    const postByStatus =
      fetchedPostsByStatus && fetchedPostsByStatus[0]
        ? fetchedPostsByStatus[0]
        : null;
    setPostByStatus(postByStatus);
  };

  const requestPostsByStatus = async (params: RequestPostsByStatusParams) => {
    if (params.type === 'all') {
      const keys: string[][] = [
        // все публичные посты
        [`status:public`],
        // все посты только для подписчиков (если является подписчиком)
        [`meta:subscriptions`],
      ];
      if (params.club) {
        // посты только для членов клуба
        keys.push([
          `status:inClub`,
          `status:club${params.club.id}`,
        ]);
      }

      if (params.total) {
        const fetchedNumberOfPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: true,
        });
        if (params.mode === 'state') {
          setNumberOfPostsByStatus(fetchedNumberOfPostsByStatus);
        } else if (params.mode === 'get') {
          return fetchedNumberOfPostsByStatus || undefined;
        }
      } else {
        const fetchedPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: false,
        });
        if (params.mode === 'state') {
          setPostsByStatus(fetchedPostsByStatus);
        } else if (params.mode === 'get') {
          return fetchedPostsByStatus;
        }
      }
    } else if (params.type === 'audios') {
      dispatchAudioPostsByStatus({
        type: 'FETCH_POSTS_START',
      });
      const keys: string[][] = [
        [`status:public`, `status:audio`],
        [`meta:subscriptions`, `status:audio`],
      ];
      if (params.club) {
        // посты только для членов клуба
        keys.push([
          `status:inClub`,
          `status:club${params.club.id}`,
          `status:audio`,
        ]);
      }

      const fetchedAudioPostsByStatus = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });
      dispatchAudioPostsByStatus({
        type: 'FETCH_POSTS_SUCCESS',
        payload: {
          posts: fetchedAudioPostsByStatus,
          newOffset: audioPostsByStatus.offset + PAGINATION_STEP,
        },
      });

      if (
        !fetchedAudioPostsByStatus ||
        fetchedAudioPostsByStatus?.length < PAGINATION_STEP
      ) {
        dispatchAudioPostsByStatus({ type: 'SET_HAS_MORE', payload: false });
      }

      dispatchAudioPostsByStatus({
        type: 'FETCH_POSTS_FINISH',
      });
    } else if (params.type === 'author') {
      const keys: string[][] = [
        // публичные посты автора
        [`status:author${params.authorId}`, `status:public`],
        // посты только для подписчиков (если является подписчиком)
        [`status:author${params.authorId}`, `meta:subscriptions`],
      ];
      if (params.isOwnerOfPosts) {
        // посты только для членов клуба или закрепы
        keys.push([`status:author${params.authorId}`]);
      }
      if (params.club) {
        // посты только для членов клуба
        keys.push([
          `status:author${params.authorId}`,
          `status:inClub`,
          `status:club${params.club.id}`,
        ]);
      }
      if (params.total) {
        const fetchedNumberOfPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: true,
        });

        setNumberOfPostsByStatus(fetchedNumberOfPostsByStatus);
      } else {
        const fetchedPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: false,
        });
        setPostsByStatus(fetchedPostsByStatus);
        return fetchedPostsByStatus;
      }
    } else if (params.type === 'draft') {
      const keys: string[][] = [
        // публичные посты автора
        [`status:draft${params.authorId}`],
      ];
      if (params.total) {
        const fetchedNumberOfPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: true,
        });

        setNumberOfPostsByStatus(fetchedNumberOfPostsByStatus);
      } else {
        const fetchedPostsByStatus = await getPostsByStatus({
          ...params,
          keys,
          total: false,
        });
        setPostsByStatus(fetchedPostsByStatus);
        return fetchedPostsByStatus;
      }
    } else if (params.type === 'search') {
      dispatchPostsSearchResultByStatus({
        type: 'FETCH_POSTS_START',
      })
      const keys: string[][] = [
        // публичные посты
        [params.text, `status:public`],
        // посты только для подписчиков (если является подписчиком)
        [params.text, `meta:subscriptions`],
        // велком любого клуба
        [params.text, `status:welcomeClub`],
        // избранное
        [params.text, `status:featured`],
      ];
      if (params.club) {
        // все виды клубных постов
        keys.push([
          params.text,
          `status:club${params.club.id}`,
        ]);
      }
      if (params.currentUserId) {
        // если автор, то все посты (кроме черновиков)
        keys.push([
          params.text,
          `status:author${params.currentUserId}`,
        ]);
        // все общие закрепы
        keys.push([
          params.text, `status:pinnedPublic`
        ]);
      } else {
        // все общие велкомы
        keys.push([
          params.text, `status:welcomePublic`,
        ]);
      }

      const fetchedPostsSearchResultByStatus = await getPostsByStatus({
        ...params,
        keys,
        total: false,
        byWeight: true,
      });

      if (params.isNewSearch) {
        dispatchPostsSearchResultByStatus({
          type: 'FETCH_NEW_POSTS_SUCCESS',
          payload: {
            posts: fetchedPostsSearchResultByStatus,
            newOffset: postsSearchResultByStatus.offset + PAGINATION_STEP,
          }
        })
      } else {
        dispatchPostsSearchResultByStatus({
          type: 'FETCH_POSTS_SUCCESS',
          payload: {
            posts: fetchedPostsSearchResultByStatus,
            newOffset: postsSearchResultByStatus.offset + PAGINATION_STEP,
          }
        })
      }

      if (
        !fetchedPostsSearchResultByStatus ||
        fetchedPostsSearchResultByStatus?.length < PAGINATION_STEP
      ) {
        dispatchPostsSearchResultByStatus({
          type: 'SET_HAS_MORE',
          payload: false,
        });
      }

      dispatchPostsSearchResultByStatus({
        type: 'FETCH_POSTS_FINISH',
      })
    } else if (params.type === 'reposted') {
      dispatchRepostedPostsByStatus({
        type: 'FETCH_POSTS_START',
      });

      const keys: string[][] = [
        // публичные посты автора
        [`status:repost${params.repostsOwnerId}`, `status:public`],
        // посты только для подписчиков (если является подписчиком)
        [`status:repost${params.repostsOwnerId}`, `meta:subscriptions`],
        // велком любого клуба
        [`status:repost${params.repostsOwnerId}`, `status:welcomeClub`],
        // избранное
        [`status:repost${params.repostsOwnerId}`, `status:featured`],
      ];

      if (params.currentUserId) {
        // все общие закрепы
        keys.push([
          `status:repost${params.repostsOwnerId}`,
          `status:pinnedPublic`
        ]);
      }

      if (params.club) {
        // посты только для членов клуба
        keys.push([
          `status:repost${params.repostsOwnerId}`,
          `status:club${params.club.id}`,
        ]);
      }

      const fetchedRepostedPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      dispatchRepostedPostsByStatus({
        type: 'FETCH_POSTS_SUCCESS',
        payload: {
          posts: fetchedRepostedPosts,
          newOffset: repostedPostsByStatus.offset + PAGINATION_STEP,
        },
      });

      if (
        !fetchedRepostedPosts ||
        fetchedRepostedPosts?.length < PAGINATION_STEP
      ) {
        dispatchRepostedPostsByStatus({ type: 'SET_HAS_MORE', payload: false });
      }

      dispatchRepostedPostsByStatus({
        type: 'FETCH_POSTS_FINISH',
      });
    } else if (params.type === 'similar') {
      const keys: string[][] = [
        // публичные посты автора
        [`similar:${params.postId}`, `status:public`],
        // посты только для подписчиков (если является подписчиком)
        [`similar:${params.postId}`, `meta:subscriptions`],
      ];

      if (params.club) {
        // посты только для членов клуба
        keys.push([
          `similar:${params.postId}`,
          `status:inClub`,
          `status:club${params.club.id}`,
        ]);
      }

      const fetchedSimilarPosts = await getPostsByStatus({
        keys,
        limit: PAGINATION_STEP,
        byWeight: true,
        total: false,
      });

      setSimilarPostsByStatus(fetchedSimilarPosts);
    } else if (params.type === 'tag') {
      const keys: string[][] = [
        // публичные посты
        [`tag:${params.tag}`, `status:public`],
        // посты только для подписчиков (если является подписчиком)
        [`tag:${params.tag}`, `meta:subscriptions`],
        // велком любого клуба
        [`tag:${params.tag}`, `status:welcomeClub`],
        // избранное
        [`tag:${params.tag}`, `status:featured`],
      ];

      if (params.club) {
        // все виды клубных постов
        keys.push([
          `tag:${params.tag}`,
          `status:club${params.club.id}`,
        ]);
      }
      if (params.currentUserId) {
        // если автор, то все посты (кроме черновиков)
        keys.push([
          `tag:${params.tag}`,
          `status:author${params.currentUserId}`
        ]);
        // все общие закрепы
        keys.push([
          `tag:${params.tag}`, `status:pinnedPublic`
        ]);
      } else {
        // все общие велкомы
        keys.push([
          `tag:${params.tag}`, `status:welcomePublic`,
        ]);
      }

      const fetchedTagPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedTagPosts;
    } else if (params.type === 'club') {
      const keys: string[][] = [
        // публичные посты членов клуба
        [`status:public`, `status:club${params.club.id}`],
        // посты членов клуба только для подписчиков
        [`meta:subscriptions`, `status:club${params.club.id}`],
        // Только клубные посты
        [`status:inClub`, `status:club${params.club.id}`],
      ];

      const fetchedClubPostsByStatus = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedClubPostsByStatus;
    } else if (params.type === 'welcomeClub') {
      const keys: string[][] = [[
        `status:welcomeClub`,
        `status:club${params.club.id}`,
      ]];
      const fetchedWelcomeClubPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedWelcomeClubPosts;
    } else if (params.type === 'pinnedClub') {
      const keys: string[][] = [[
        `status:pinnedClub`,
        `status:club${params.club.id}`,
      ]];
      const fetchedPinnedClubPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedPinnedClubPosts;
    } else if (params.type === 'pinnedPublic') {
      const keys: string[][] = [[`status:pinnedPublic`]];
      const fetchedPinnedPublicPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedPinnedPublicPosts;
    } else if (params.type === 'welcomePublic') {
      const keys: string[][] = [[`status:welcomePublic`]];
      const fetchedWelcomePublicPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedWelcomePublicPosts;
    } else if (params.type === 'featured') {
      const keys: string[][] = [[`status:featured`]];
      const fetchedFeaturedPosts = await getPostsByStatus({
        ...params,
        keys,
        total: false,
      });

      return fetchedFeaturedPosts;
    }
  };

  const setNewAudioPostsCountState = async () => {
    try {
      const response = await axios.get(
        `${links.origin}/apiweb/post.php?action=total&all=1&audio=1&newest=1`,
      );
      const newAudioPostsCount = response.data.total;
      if (Number.isInteger(newAudioPostsCount)) {
        setNewAudioPostsCount(newAudioPostsCount);
        return true;
      }

      return false;
    } catch (e) {
      console.log('setNewAudioPostsCountState');
      return false;
    }
  };

  const requestCountNewAudioPostsByStatus = async (club: Club | null) => {
    const keys: string[][] = [
      ['status:public', 'meta:newaudio'],
      ['meta:subscriptions', 'meta:newaudio'],
    ];

    if (club) {
      // пост только для членов клуба
      keys.push([
        `status:inClub`,
        `status:club${club.id}`,
        'meta:newaudio',
      ]);
    }

    const fetchedCountNewAudioPostsByStatus = await getPostsByStatus({
      keys,
      total: true,
    });

    setCountNewAudioPostsByStatus(fetchedCountNewAudioPostsByStatus);
  };

  const repostedPostDeletedStateUpdate = async (
    userId: string,
    postId: string,
    isRepostPreview?: boolean,
  ): Promise<boolean> => {
    const isDeleted = await deleteRepostPost(postId);
    const isStatusKeyDeleted = await updatePostStatus('del', postId, [
      `repost${userId}`,
    ]);

    if (!isDeleted || !isStatusKeyDeleted) {
      return false;
    }

    dispatchRepostedPostsByStatus({
      type: 'REMOVE_POST_BY_ID',
      payload: postId,
    });
    return true;
  };

  // TODO postsStatus delete after playlist statuses?
  useEffect(() => {
    const playlistPosts =
      playlist?.posts &&
      Object.keys(playlist.posts)
        .map((key) => playlist.posts[key])
        .filter((p) => p);
    playlistPosts && setPostOwnersState(playlistPosts);
  }, [postsSearchResultByStatus, similarPosts, playlist]);

  const removePost = async (
    id: string,
    audioOnly: boolean,
  ): Promise<boolean> => {
    const isDeleted = await deletePost(id, audioOnly);

    if (!audioOnly && isDeleted) {
      setPostByStatus(null);
    }

    if (audioOnly && isDeleted) {
      const newPostByStatus: PostData | undefined = postByStatus
        ? {
            ...postByStatus,
            audiosec: undefined,
            audiosrc: undefined,
          }
        : undefined;

      setPostByStatus(newPostByStatus);
    }

    return isDeleted;
  };

  const updatePost = async ({
    postId,
    date,
    text,
    viewMode,
    audio,
    audiosec,
    tags,
    keys,
    addKeys,
    delKeys,
  }: UpdatePostParams): Promise<boolean> => {
    const result = await savePost({
      create: false,
      postId,
      date,
      text,
      viewMode,
      audio,
      audiosec,
      tags,
      keys,
      addKeys,
      delKeys,
    });

    if (result) {
      const newPostByStatus: PostData = {
        ...postByStatus,
        ...result,
      };

      setPostByStatus(newPostByStatus);
    }

    return Boolean(result);
  };

  const setCurrentAudioPostState = async (
    value?: CurrentAudioPostData | null,
  ) => {
    if (value === null) {
      setCurrentAudioPost(value);
      return;
    }

    if (value?.audiosrc) {
      setCurrentAudioPost(value);
      return;
    }

    const markData = await getAudioMark();

    if (!markData?.blogid) return;

    const post = await getPostWithRecommendations(markData.blogid, true);

    if (!post?.audiosrc || !post.audiosec) return;

    const currentAudioPost: CurrentAudioPostData = {
      ...post,
      audiosec: post.audiosec,
      audiosrc: post.audiosrc,
      isBugged: false,
    };

    setCurrentAudioPost(currentAudioPost);
  };

  const setTotalPlaylistDurationState = () => {
    const duration = playlist?.ids.reduce((sum, id) => {
      const sec = playlist.posts[id]?.audiosec;

      if (sec) {
        return sum + sec;
      }

      return 0;
    }, 0);

    setTotalPlaylistDuration(duration);
  };

  useEffect(() => {
    setTotalPlaylistDurationState();
  }, [playlist]);

  const setPlaylistState = async () => {
    const playlist = await getPlaylist();
    setPlaylist(playlist);
  };

  const updatePlaylistState = async (value: string | string[]) => {
    let newIds: string[] = playlist?.ids ? [...playlist.ids] : [];
    if (_isArray(value)) {
      newIds = value;
    } else if (playlist?.ids.includes(value)) {
      if (!confirm(t('confirmRemovalFromPlaylist'))) {
        return;
      }
      newIds = clearPlaylistFromEmpty(playlist, value);
    } else {
      newIds.push(value);
    }

    const isSent = await savePlaylist(newIds);

    if (!isSent) {
      alert(t('serverError'));
    }

    await setPlaylistState();
  };

  const requestForCheckCommercialChallenge = async (userId: string) => {
    const response = await axios.get(
      `${links.challengesOrigin}/api/challenges/registration/check-active-commercial`,
      {
        params: {
          user_id: userId,
        },
      },
    );

    setIsParticipantInCommercialChallenge(!!response.data);
  };

  useEffect(() => {
    if (
      !currentUser ||
      currentUser.isPremium ||
      currentUser.role !== UserRole.STUDENT
    ) {
      return;
    }

    requestForCheckCommercialChallenge(currentUser.id);
  }, [currentUser]);

  const requestPinnedClubpostsByStatus = async (club: Club, userClub: Club | null, currentUser?: User) => {
    const pinnedPosts: PostData[] = [];
    const fetchedPinnedPosts = await requestPostsByStatus({
      type: currentUser ? 'pinnedPublic' : 'welcomePublic',
      limit: 1,
    })

    if (Array.isArray(fetchedPinnedPosts)) {
      pinnedPosts.push(...fetchedPinnedPosts);
    }
    const fetchedPinnedClubPosts = await requestPostsByStatus({
      // Если пользователь в клубе, то закрепы клуба иначе велкомы
      type: club.id === userClub?.id ? 'pinnedClub' : 'welcomeClub',
      club,
      limit: 5,
    });

    if (Array.isArray(fetchedPinnedClubPosts)) {
      pinnedPosts.push(...fetchedPinnedClubPosts);
    }

    setPinnedClubPostsByStatus(pinnedPosts);
  }

  const requestClubsFeedByStatus = async (
    club: Club,
    isClubMember: boolean,
    isGod: boolean,
  ) => {
    dispatchClubsFeedByStatus({
      type: 'FETCH_FEED_START',
    });

    const draftClubFeed: ClubFeed = [];

    if (isClubMember || isGod) {
      const fetchedClubPosts = clubsFeedByStatus.hasMorePosts
        ? await requestPostsByStatus({
            type: 'club',
            club,
            from: clubsFeedByStatus.offset,
            limit: PAGINATION_STEP,
          })
        : [];

      if (Array.isArray(fetchedClubPosts)) {
        draftClubFeed.push(...fetchedClubPosts);
        fetchedClubPosts.length < PAGINATION_STEP &&
          dispatchClubsFeedByStatus({
            type: 'SET_HAS_MORE_POSTS',
            payload: false,
          });
      }

      if (club.id == LukoilClubId && !clubsFeedByStatus.offset) {
        const lukoilPostPromises = PostIdsForLukoilClub.map(postId => getPostWithRecommendations(postId, true));
        const loadedPosts = (await Promise.all(lukoilPostPromises)).filter(p => p) as PostData[];
        draftClubFeed.push(...loadedPosts);
      }

      try {
        const fetchedRawClubActivities = clubsFeedByStatus.hasMoreActivities
          ? await axios.get(`${links.origin}/apiweb/clubs.php`, {
              params: {
                action: 'activity',
                club_id: club.id,
                from: clubsFeedByStatus.offset,
                limit: PAGINATION_STEP,
              },
            })
          : null;

        const fetchedClubActivities: TrainingTask[] = Array.isArray(
          fetchedRawClubActivities?.data.activity,
        )
          ? fetchedRawClubActivities?.data.activity
          : [];
        draftClubFeed.push(...fetchedClubActivities);
        fetchedClubActivities.length < 20 &&
          dispatchClubsFeedByStatus({
            type: 'SET_HAS_MORE_ACTIVITES',
            payload: false,
          });
      } catch (e) {
        console.log('fetchedRawClubActivities:', e);
      }
    }

    let newClubFeed: ClubFeed = [
      ..._shuffle(draftClubFeed),
    ];

    // если лента пуста, то запросить избранное
    if (!clubsFeedByStatus.results?.length) {
      const fetchedFeaturedPosts = await requestPostsByStatus({
        type: 'featured',
        limit: 3,
      });

      if (Array.isArray(fetchedFeaturedPosts)) {
        newClubFeed = [...fetchedFeaturedPosts, ...newClubFeed];
      }
    }

    dispatchClubsFeedByStatus({
      type: 'FETCH_FEED_SUCCESS',
      payload: {
        results: newClubFeed,
        newOffset: clubsFeedByStatus.offset + PAGINATION_STEP,
      },
    });

    dispatchClubsFeedByStatus({
      type: 'FETCH_FEED_FINISH',
    });
  };

  return (
    <PostContext.Provider
      value={{
        getPostWithRecommendations,
        savePost,
        updatePostStatus,
        deletePost,
        getPostId,
        getPosts,

        similarPosts,
        setSimilarPostsState,
        similarPostsByStatus,

        postOwners,
        setPostOwnersState,

        posts,
        setPostsState,
        isPostExists,
        checkPostExists,
        postByStatus,
        setPostByStatus,
        requestPostByStatus,
        postsByStatus,
        requestPostsByStatus,
        postsErrorCode,

        repostedPostsByStatus,

        audioPostsByStatus,
        getAudioKey,
        countNewAudioPostsByStatus,
        requestCountNewAudioPostsByStatus,

        addRepostPost,
        deleteRepostPost,
        checkRepostedPosts,
        repostedPostDeletedStateUpdate,

        numberOfPostsByStatus,

        pagination,
        setPagination,

        postsSearchResultByStatus,

        removePost,
        updatePost,

        currentAudioPost,
        setCurrentAudioPostState,
        saveAudioMark,

        totalPlaylistDuration,
        playlist,
        setPlaylist,
        setPlaylistState,
        updatePlaylistState,

        getTags,

        isParticipantInCommercialChallenge,

        pinnedClubPostsByStatus,
        requestPinnedClubpostsByStatus,

        clubsFeedByStatus,
        dispatchClubsFeedByStatus,
        requestClubsFeedByStatus,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};

export const getIdFromUrl = (id?: string) => {
  return id || window.location.pathname.split('/')[2];
};

const usersPrivacy: {
  [key: string]: boolean;
} = {};

const getPostsWithoutPrivate = async (posts: PostData[]) => {
  const similarWithoutPrivate: PostData[] = [];

  for (let i of posts) {
    const userId = i.userid;

    if (!userId) {
      console.log('private case', 1);
      continue;
    }

    if (typeof usersPrivacy[userId] === 'boolean') {
      !usersPrivacy[userId] && similarWithoutPrivate.push(i);
      console.log('private case', 2, userId, usersPrivacy[userId]);
    } else {
      const isPrivateUser = await getUserPrivacy(userId);
      !isPrivateUser && similarWithoutPrivate.push(i);
      usersPrivacy[userId] = isPrivateUser;
      console.log('private case', 3, userId, usersPrivacy[userId]);
    }
  }

  return similarWithoutPrivate;
};

export const clearPlaylistFromEmpty = (
  playlist: PlaylistData,
  value?: string,
): string[] => {
  return playlist.ids.filter((id) => {
    return playlist.posts[id]?.audiosec && id !== value;
  });
};

type DefaultPostsByStatusParams = {
  total?: boolean;
} & (
  | {
      from?: number;
      limit?: number;
      firstDate?: never;
      lastDate?: never;
    }
  | {
      firstDate?: string;
      lastDate?: string;
      from?: never;
      limit?: never;
    }
);

type PostsByAllStatusParams = {
  mode: 'state' | 'get';
  type: 'all';
  club: Club | null;
} & DefaultPostsByStatusParams;
type PostsByAllAudiosStatusParams = {
  type: 'audios';
  club: Club | null;
} & DefaultPostsByStatusParams;
type PostsByAuthorStatusParams = {
  type: 'author';
  isOwnerOfPosts: boolean;
  authorId: string;
  club: Club | null;
} & DefaultPostsByStatusParams;
type PostsByDraftStatusParams = {
  type: 'draft';
  authorId: string;
} & DefaultPostsByStatusParams;
type PostsByClubStatusParams = {
  type: 'club';
  club: Club;
} & DefaultPostsByStatusParams;
type PostsByRepostedStatusParams = {
  type: 'reposted';
  repostsOwnerId: string;
  currentUserId?: string;
  club: Club | null;
} & DefaultPostsByStatusParams;
type PostsBySimilarStatusParams = {
  type: 'similar';
  postId: string;
  club: Club | null;
};
type PostsBySearchStatusParams = {
  type: 'search';
  text: string;
  club: Club | null;
  currentUserId?: string;
  isNewSearch?: boolean;
} & DefaultPostsByStatusParams;
type PostsByTagStatusParams = {
  type: 'tag';
  tag: string;
  club: Club | null;
  currentUserId?: string;
} & DefaultPostsByStatusParams;
type PostsByPinnedClubStatusParams = {
  type: 'pinnedClub';
  club: Club;
} & DefaultPostsByStatusParams;
type PostsByWelcomeClubStatusParams = {
  type: 'welcomeClub';
  club: Club;
} & DefaultPostsByStatusParams;
type PostsByPinnedPublicStatusParams = {
  type: 'pinnedPublic';
} & DefaultPostsByStatusParams;
type PostsByWelcomePublicStatusParams = {
  type: 'welcomePublic';
} & DefaultPostsByStatusParams;
type PostsByFeaturedStatusParams = {
  type: 'featured';
} & DefaultPostsByStatusParams;

type RequestPostsByStatusParams =
  | PostsByAllStatusParams
  | PostsByAllAudiosStatusParams
  | PostsByAuthorStatusParams
  | PostsByDraftStatusParams
  | PostsByClubStatusParams
  | PostsByRepostedStatusParams
  | PostsBySimilarStatusParams
  | PostsBySearchStatusParams
  | PostsByTagStatusParams
  | PostsByPinnedClubStatusParams
  | PostsByWelcomeClubStatusParams
  | PostsByPinnedPublicStatusParams
  | PostsByWelcomePublicStatusParams
  | PostsByFeaturedStatusParams;

type RequestPostByStatusParams = {
  postId: string;
  club: Club | null;
  currentUserId: string | null;
  managerClubId?: [];
  trainerClubIds?: [];
};

interface ClubsFeedByStatus {
  results?: ClubFeed | null;
  offset: number;
  isLoading: boolean;
  hasMorePosts: boolean;
  hasMoreActivities: boolean;
}

const initialClubsFeedByStatus: ClubsFeedByStatus = {
  offset: 0,
  isLoading: false,
  hasMorePosts: true,
  hasMoreActivities: true,
};

export type ClubsFeedByStatusActionType =
  | { type: 'FETCH_FEED_START' }
  | { type: 'FETCH_FEED_FINISH' }
  | {
      type: 'FETCH_FEED_SUCCESS';
      payload: {
        results: ClubFeed | null;
        newOffset: number;
      };
    }
  | {
      type: 'ADD_ONE_POST';
      payload: {
        post: PostData;
      };
    }
  | { type: 'SET_HAS_MORE_POSTS'; payload: boolean }
  | { type: 'SET_HAS_MORE_ACTIVITES'; payload: boolean };

export const reducerClubsFeedByStatus = (
  tagSearchResults: ClubsFeedByStatus,
  action: ClubsFeedByStatusActionType,
): ClubsFeedByStatus => {
  switch (action.type) {
    case 'FETCH_FEED_START':
      return { ...tagSearchResults, isLoading: true };
    case 'FETCH_FEED_FINISH':
      return { ...tagSearchResults, isLoading: false };
    case 'FETCH_FEED_SUCCESS':
      return {
        ...tagSearchResults,
        results: action.payload.results
          ? tagSearchResults.results
            ? [...tagSearchResults.results, ...action.payload.results]
            : action.payload.results
          : tagSearchResults.results ?? null,
        offset: action.payload.newOffset,
      };
    case 'ADD_ONE_POST':
      return { 
        ...tagSearchResults, 
        results: tagSearchResults.results ? [action.payload.post, ...tagSearchResults.results] : [action.payload.post]
      }
    case 'SET_HAS_MORE_POSTS':
      return { ...tagSearchResults, hasMorePosts: action.payload };
    case 'SET_HAS_MORE_ACTIVITES':
      return { ...tagSearchResults, hasMoreActivities: action.payload };
    default:
      return tagSearchResults;
  }
};
