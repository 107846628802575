import React, { FunctionComponent, useContext, useEffect } from "react";
import { StudentInfo, TrainerBaseInfo } from "/src/domain/dashboard/DashboardData";
import { Club } from "/src/domain/club/ClubData";
import { CurrentUserContext, CurrentUserContextProps } from "/src/contexts/shared/CurrentUserContext";
import { UserContext, UserContextProps } from "/src/contexts/shared/UserContext";
import { ClubContext, ClubContextProps } from "/src/contexts/ClubContext";
import { PostContext, PostContextProps, SavePostParams } from "/src/contexts/PostContext";
import { PostButton } from "/src/ui/studentTasks/studentTasks/posts/PostButton";
import { PostView } from "/src/ui/studentTasks/studentTasks/posts/PostView";
import { PostsStub } from "./PostsStub";
import { Box, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PostViewMode } from "/src/domain/studentTasks/StudentTasksData";

interface PostsProps {
    className?: string;
    postOwner: StudentInfo | TrainerBaseInfo;
    ownerTrainerId?: string;
    sx?: SxProps;
    isOwnerOfPosts: boolean;
    clubManagement?: Club;
}

export const Posts: FunctionComponent<PostsProps> = ({
    className,
    postOwner,
    ownerTrainerId,
    sx,
    isOwnerOfPosts,
    clubManagement,
}) => {
    const { t } = useTranslation();
    const { currentUser, currentDate } = useContext(CurrentUserContext) as CurrentUserContextProps;
    const { godIds } = useContext(UserContext) as UserContextProps;
    const { userClub } = useContext(ClubContext) as ClubContextProps;

    const { 
        savePost, 
        getPostId,
        postsByStatus,
        requestPostsByStatus,
        deletePost,
    } = useContext(PostContext) as PostContextProps;

    useEffect(() => {
        if (userClub !== undefined) {
            requestPostsByStatus({
                type: "author",
                club: userClub,
                authorId: postOwner.id,
                isOwnerOfPosts,
            })
        }
    }, [userClub])

    const updatePost = async ({
        create,
        postId,
        date,
        text,
        viewMode,
        audio,
        audiosec,
        tags,
        keys,
        addKeys,
        delKeys,
    }: SavePostParams): Promise<boolean> => {
        const newPost = await savePost({
            create,
            postId,
            date,
            text,
            viewMode,
            audio,
            audiosec,
            tags,
            keys,
            addKeys,
            delKeys,
        });

        if (!newPost) {
            return false;
        }

        await requestPostsByStatus({
            type: "author",
            club: userClub || null,
            authorId: postOwner.id,
            isOwnerOfPosts,
        })
        return true;  
    }

    const removePost = async (postId: string, audioOnly: boolean) => {
        const isDeleted = await deletePost(postId, audioOnly);

        if (!isDeleted) {
            return false;
        }

        await requestPostsByStatus({
            type: "author",
            club: userClub || null,
            authorId: postOwner.id,
            isOwnerOfPosts,
        })
        return true;
    }

    if (userClub === undefined) return null;

    return <Box className={className} sx={sx}>
        {isOwnerOfPosts && currentUser && <PostButton
            getPostId={getPostId}
            createPost={(args) => updatePost({create: true, ...args})}
            currentDate={currentDate}
            isPremium={!!currentUser?.isPremium}
            currentUser={currentUser}
            userClub={userClub}
            clubManagement={clubManagement}
        />}
        {postsByStatus === null || postsByStatus?.length === 0 ? <PostsStub/>
        : postsByStatus?.map((post) => 
            <PostView
                key={post.postid}
                updatePost={(args) => updatePost({create: false, ...args})}
                removePost={removePost}
                currentDate={currentDate}
                isReadOnly={!isOwnerOfPosts}
                type={"post"}
                {...post}
                isPreview
                currentUserId={currentUser?.id}
                isRepostAvailable={Boolean(ownerTrainerId && ownerTrainerId === currentUser?.id)}
                isRepostedPost={false}
                userClub={userClub}
                clubManagement={clubManagement}
                godIds={godIds}
                isShortHeader={true}
            />)
        }
    </Box>
}
