import React, { FunctionComponent } from 'react';
import { TextField, SxProps, InputProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { isNumber as _isNumber } from 'lodash';

interface KmForecastInputProps {
  value?: string;
  onChange?: (value: string) => void;
  sx?: SxProps;
  InputProps?: Partial<InputProps>;
  label?: string;
  fullWidth?: boolean;
  helperText?: string;
}

export const KmForecastInput: FunctionComponent<KmForecastInputProps> = ({
  value,
  onChange,
  sx,
  InputProps,
  label,
  fullWidth,
  helperText,
}) => {
  const { t } = useTranslation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onChange) return;

    let inputText = e.target.value.replace(',', '.'); // Заменяем запятую на точку

    // Разрешаем вводить только числа и точку
    if (!/^\d*\.?\d?$/.test(inputText) && inputText !== '') {
      return;
    }

    onChange(inputText);
  };

  return (
    <TextField
      inputProps={{
        maxLength: 3,
      }}
      sx={sx}
      label={label}
      InputProps={InputProps}
      autoComplete="off"
      value={value ?? ""}
      onChange={handleChange}
      fullWidth={fullWidth}
      helperText={helperText}
    />
  );
};
