import React, { FunctionComponent } from "react";
import { TrainingType } from "/src/domain/studentTasks/StudentTasksData";
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from "react-i18next"
import { trainingText, trainingColors } from "/src/domain/studentTasks/trainingInfo";

interface TrainingTypeProps {
    type: TrainingType;
    main6?: boolean;
    isFutureTask: boolean;
}

export const TrainingTypeView: FunctionComponent<TrainingTypeProps> = ({ type, main6, isFutureTask }) => {
    const { t } = useTranslation()
    const classes = useStyles({type})

    if (!+type) return null;
    return (
        <div className={classes.taskType}>
            {!!main6 && type == TrainingType.COMPETITION && isFutureTask && <span className={classes.mainStart}>
                { t('main') } | {" "}
            </span>}
            { t(trainingText[type])}
        </div>
    )
}

interface TrainingTypeProps {
    type: TrainingType;
}

const useStyles = makeStyles(theme => ({
    taskType: (props: Pick<TrainingTypeProps, 'type'>) => ({
        fontSize: "1.1rem",
        fontWeight: 500,
        marginTop: "0.5rem",
        position: "relative",
        textAlign: "end",
        color: trainingColors[props.type],
        "&::before": {
            content: "''",
            display: "inline-block",
            position: "relative",
            left: "-1rem",
            bottom: "0.15rem",
            height: "0.5rem",
            width: "0.5rem",
            borderRadius: "50%",
            backgroundColor: trainingColors[props.type]
        }
    }),
    mainStart: {
        color: theme.palette.secondary.main
    }
}))
