import React, { Children, FunctionComponent } from "react";
import { Dialog, AppBar, Toolbar, IconButton, 
    useMediaQuery, useTheme } from "@mui/material";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from "react-i18next";

interface DialogListWrapperProps {
    open: boolean;
    onClose: () => void;
    fullWidth?: boolean;
    disableEnforceFocus?: boolean;
    PaperProps?: Partial<PaperProps<"div", {}>> | undefined;
    isDraggable?: boolean;
}

const PaperComponent = (props: PaperProps) => {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
      <Draggable
        nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} ref={nodeRef} />
      </Draggable>
    );
  }
  

export const DialogListWrapper: FunctionComponent<DialogListWrapperProps> = ({ 
    open,
    onClose,
    fullWidth,
    disableEnforceFocus,
    PaperProps,
    children,
    isDraggable,
}) => {
    const { t } = useTranslation();

    const downSmMediaQuery = useMediaQuery(useTheme().breakpoints.down("sm"));

    return <Dialog
        fullScreen={downSmMediaQuery}
        fullWidth={fullWidth}
        disableEnforceFocus={disableEnforceFocus}
        open={open} 
        onClose={onClose} 
        sx={{ zIndex: 1100 }}
        PaperProps={PaperProps}
        scroll="paper"
        PaperComponent={isDraggable ? PaperComponent : undefined}
    >
        <AppBar sx={{ position: "relative" }}
            style={{ cursor: isDraggable ? 'move' : 'initial' }} 
            id="draggable-dialog-title"
        >
            <Toolbar sx={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <IconButton onClick={onClose}>
                    <CloseIcon sx={{ color: "primary.contrastText" }}/>
                </IconButton>
            </Toolbar>
        </AppBar>
        { children }
    </Dialog>

}
